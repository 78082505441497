<template>
  <div>
    <div class="launcher text-center">
      <div class="mt-4">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </div>
      <br />
      <pre>{{ authMessage || 'Loading...' }}</pre>
    </div>
    <!-- <pre>{{ state }}</pre> -->
  </div>
</template>

<script>
// /launcher/98772adb-72bc-11eb-a29b-005056a7b550/5f6c99798e904f2e2bdc7aa5

import Vue from 'vue';
import '@/lib/log';

import router from '@/router';
import { defineComponent, watch } from '@vue/composition-api';

// Auth composable
import { useAuth } from '@/module/auth';

export default defineComponent({
  name: 'AppLauncher',
  props: ['portalToken', 'appId'], // incoming from route

  setup(props) {
    Vue.$log.debug('Launching...', props);

    const { isAuthorized, isAuthenticated, authMessage, authenticateApp } = useAuth();
    authenticateApp({ appId: props.appId, portalToken: props.portalToken });

    watch(isAuthenticated, (v) => {
      if (v === true) {
        router.push({ name: process.env.VUE_APP_DEFAULT_COMPONENT });
      }
    });

    return { isAuthorized, isAuthenticated, authMessage };
  }
});
</script>

<style lang="scss">
.launcher {
  margin-top: 6rem;
  text-align: center;
}
.message {
  text-align: center;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1.15px;
  margin-top: -7em;
  font-weight: 700;
}
</style>
