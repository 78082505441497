/**
 * An error whose message is safe to show to a user.
 */
export class UserMessageError extends Error {
  constructor(message: string, protected readonly previousError?: Error) {
    super(message);
  }

  getPreviousError(): Error | undefined {
    return this.previousError;
  }
}
