import { useAuth } from '@/module/auth';
import router from '@/router';
import axios from 'axios';
import Vue from 'vue';
import { createToastInterface } from 'vue-toastification';

const toast = createToastInterface({ maxToasts: 5 });
const { portalLogin } = useAuth();

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.baseURL =
  process.env.VUE_APP_API_BASE_URL || 'https://enal2d48no6zqw3.m.pipedream.net';

// Add pStarted so we can calculate latency on response interceptor
axios.interceptors.request.use(
  (config) => {
    return {
      ...config,
      pStarted: performance.now()
    };
  },
  (error) => Promise.reject(error)
);

// Make sure all requests are at least .5 seconds to avoid the annoying flashes
axios.interceptors.response.use(
  async (response) => {
    const minimumDelay = 500;
    // @ts-ignore
    const latency = performance.now() - response.config.pStarted;
    const shouldNotDelay = minimumDelay < latency;

    if (shouldNotDelay) {
      return response;
    }

    const remainder = minimumDelay - latency;
    const [responseWithDelay] = await Promise.all([
      response,
      new Promise((resolve) => setTimeout(resolve, remainder))
    ]);

    return responseWithDelay;
  },
  (error) => Promise.reject(error)
);

// Intercept 401 and 403 and send back to portal
axios.interceptors.response.use(
  (res) => {
    return Promise.resolve(res);
  },
  (err) => {
    const error = err.response;
    Vue.$log.error('lib/http: Axios interceptor error:', err.response);
    const status = err.response.status;

    // This might be a reply from the Portal. It's error object is different than Nest
    // TODO: Update portal verifyAppToken response to consistent with Nest
    if (status === 401 || status === 403) {
      portalLogin();
    } else if (status === 503) {
      router.push({ name: 'MaintenanceMode' });
    }
    return Promise.reject(err);
  }
);

axios.interceptors.request.use();
