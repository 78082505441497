var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("AuthBridgePage", {
        attrs: {
          publicAuthBridgeUuid: _vm.publicAuthBridgeUuid,
          domoSetLoadJobUuid: _vm.domoSetLoadJobUuid,
          action: _vm.action,
          defaultRegion: _vm.region
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }