






















import Vue from 'vue';
import { defineComponent, ref } from '@vue/composition-api';
import DomainDialog from '@/views/app/domains/DomainDialog.vue';
import { useDomainApi, DomainModel } from '@/module/api/domain';
import router from '@/router';

const { selectedItem: selectedDomain } = useDomainApi();

const domainDialog = ref<any>(null);

const viewDomain = () => {
  domainDialog.value.view(selectedDomain.value);
};

const initializeData = async (domainUuid: string) => {
  if (!domainUuid) {
    Vue.$log.debug(`Initialize TopBarTitle with NO domainUuid: ${domainUuid}`);
    return;
  }
};

const goHome = () => {
  router.push({
    name: 'DomainList'
  });
};

export default defineComponent({
  name: 'TopBarTitle',
  components: { DomainDialog },
  props: ['uuid'],
  setup(props) {
    initializeData(props.uuid);

    return {
      goHome,
      domainDialog,
      viewDomain,
      selectedDomain
    };
  }
});
