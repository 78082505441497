var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.selectedDomain.uuid
      ? _c(
          "div",
          [
            _c(
              "v-btn",
              {
                staticClass: "text-subtitle-1",
                attrs: { text: "" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.goHome.apply(null, arguments)
                  }
                }
              },
              [_vm._v(" DAMM ")]
            ),
            _c("span", { staticClass: "grey--text ml-0 mr-3" }, [_vm._v("|")]),
            _c("span", { staticClass: "text-subtitle-1" }, [
              _vm._v(_vm._s(_vm.selectedDomain.label))
            ]),
            _c(
              "v-btn",
              {
                attrs: { icon: "", color: "white" },
                on: { click: _vm.viewDomain }
              },
              [_c("v-icon", [_vm._v("mdi-information-outline")])],
              1
            ),
            _c("domain-dialog", { ref: "domainDialog" })
          ],
          1
        )
      : _c(
          "div",
          [
            _c(
              "v-btn",
              {
                staticClass: "text-subtitle-1",
                attrs: { text: "" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.goHome.apply(null, arguments)
                  }
                }
              },
              [_vm._v(" DAMM ")]
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }