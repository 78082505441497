<script>
import { defineComponent, computed } from '@vue/composition-api';

// Composables for UI (drawer, feedback, wait) and General (sysconfig, name, etc.)
import { useUi } from '@/module/ui';
import { useGeneral } from '@/module/general';

export default defineComponent({
  name: 'SplashTopBar',
  props: {
    source: String
  },
  setup(_, context) {
    const lgAndUp = computed(() => context.root.$vuetify.breakpoint.lgAndUp);
    const { drawerToggle } = useUi();
    const { appName, appLogoFile } = useGeneral();
    return {
      lgAndUp,
      appName: appName,
      logo: appLogoFile,
      drawerToggle
    };
  }
});
</script>
<style lang="scss" scoped>
.v-application a {
  color: #fff;
  text-decoration: none;
}
</style>
