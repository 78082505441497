<template>
  <div>
    <v-navigation-drawer app clipped :value="drawer" :mini-variant="!drawer" :width="sidebarWidth">
      <v-layout fill-height flex-column justify-start>
        <v-list>
          <nav-drawer-menu :drawer="drawer" :data="appMenuData"></nav-drawer-menu>
        </v-list>
        <v-list v-if="isAdmin">
          <nav-drawer-menu :drawer="drawer" :data="adminMenuData"></nav-drawer-menu>
        </v-list>
        <v-list class="mt-auto">
          <!-- <div class="align-self-end flex-grow-1">
            <nav-drawer-menu :drawer="drawer" :data="systemMenuData"></nav-drawer-menu>
          </div> -->
          <v-divider />
          <ref-version class="text-center mb-0 mt-1" />
        </v-list>
      </v-layout>
      <FeedbackFormModal :feedback.sync="feedback"></FeedbackFormModal>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from '@vue/composition-api';

import NavDrawerMenu from '@/components/navigation/NavDrawerMenu.vue';
import FeedbackFormModal from '@/components/feedback/FeedbackFormModal.vue';

// Keep your menu configuration with your views
//
//import AModuleMenu from '@/views/a-module/menu';
//import BModuleMenu from '@/views/b-module/menu';
import appMenuData from '@/views/app/menu';
import adminMenuData from '@/views/admin/menu';
import systemMenuData from '@/views/system/menu';

// UI Composable
import { useUi } from '@/module/ui';
// Admin Composable
import { useAuth } from '@/module/auth';

export default defineComponent({
  name: 'AppNavDrawer',
  components: { NavDrawerMenu, FeedbackFormModal },
  setup(_, context) {
    const selectedMenuItem = ref(0);
    const sidebarWidth = 320;
    const sidebarMinWidth = 96;
    const lgAndUp = computed(() => context.root.$vuetify.breakpoint.lgAndUp);
    const mdAndUp = computed(() => context.root.$vuetify.breakpoint.mdAndUp);
    const smAndDown = computed(() => context.root.$vuetify.breakpoint.smAndDown);
    const { drawer, feedback } = useUi();
    const { isAdmin } = useAuth();

    if (!isAdmin) {
      adminMenuData = [];
    }

    return {
      lgAndUp,
      mdAndUp,
      smAndDown,
      appMenuData: appMenuData,
      adminMenuData: adminMenuData,
      systemMenuData,
      sidebarWidth,
      sidebarMinWidth,
      selectedMenuItem,
      feedback,
      drawer,
      isAdmin
    };
  }
});
</script>
<style src="./AppNavDrawer.scss" lang="scss" />
