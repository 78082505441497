var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stateName.value
    ? _c(
        "span",
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _vm.isExpired.value
                          ? _c(
                              "v-icon",
                              _vm._g(
                                _vm._b(
                                  { attrs: { color: "grey darken-3" } },
                                  "v-icon",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_vm._v("mdi-clock-alert-outline")]
                            )
                          : _vm.stateName.value === "IN_PROGRESS"
                          ? _c(
                              "v-icon",
                              _vm._g(
                                _vm._b(
                                  { attrs: { color: "blue darken-3" } },
                                  "v-icon",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _vm._v(
                                  "mdi-circle-slice-" +
                                    _vm._s(
                                      _vm.getProgressSliceNumber(
                                        _vm.progress.value
                                      )
                                    )
                                )
                              ]
                            )
                          : _vm.stateName.value === "DONE"
                          ? _c(
                              "v-icon",
                              _vm._g(
                                _vm._b(
                                  { attrs: { color: "success" } },
                                  "v-icon",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_vm._v("mdi-check-circle-outline")]
                            )
                          : _vm.stateName.value === "CANCELLED"
                          ? _c(
                              "v-icon",
                              _vm._g(_vm._b({}, "v-icon", attrs, false), on),
                              [_vm._v("mdi-close-circle-outline")]
                            )
                          : _vm.stateName.value === "ERRORED"
                          ? _c(
                              "v-icon",
                              _vm._g(
                                _vm._b(
                                  { attrs: { color: "red darken-3" } },
                                  "v-icon",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_vm._v("mdi-alert-circle-outline")]
                            )
                          : _c(
                              "v-icon",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: {
                                      left: "",
                                      title: _vm.stateName.value
                                    }
                                  },
                                  "v-icon",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_vm._v("mdi-dots-horizontal-circle-outline")]
                            )
                      ]
                    }
                  }
                ],
                null,
                false,
                2538622874
              )
            },
            [
              _vm.isExpired.value
                ? _c("div", [
                    _c("b", [_vm._v("EXPIRED:")]),
                    _vm._v(
                      " Older than " +
                        _vm._s(_vm.DOMO_EXPIRATION_TTL_DAYS) +
                        " days"
                    )
                  ])
                : _c("div", [
                    _c("b", [_vm._v("Expires:")]),
                    _vm._v(" " + _vm._s(_vm.expiresIn.value))
                  ]),
              _c("div", [
                _vm._v(
                  " State: " +
                    _vm._s(_vm.getFriendlyStateName(_vm.stateName.value))
                ),
                _vm.stateName.value === "IN_PROGRESS"
                  ? _c("span", [
                      _vm._v(
                        ", " +
                          _vm._s(_vm.progress.value.totalUnitsCompleted) +
                          " of " +
                          _vm._s(_vm.progress.value.totalUnits) +
                          " completed (" +
                          _vm._s(_vm.getProgressPercent(_vm.progress.value)) +
                          "%) "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm.startedAt.value
                ? _c("div", [
                    _vm._v(
                      "Started: " +
                        _vm._s(_vm.formatDbDate(_vm.startedAt.value))
                    )
                  ])
                : _c("div", [_vm._v("Started: —")]),
              _vm.completedAt.value
                ? _c("div", [
                    _vm._v(
                      "Completed: " +
                        _vm._s(_vm.formatDbDate(_vm.completedAt.value))
                    )
                  ])
                : _c("div", [_vm._v("Completed: —")]),
              _vm.duration.value
                ? _c("div", [_vm._v("Duration: " + _vm._s(_vm.duration.value))])
                : _c("div", [_vm._v("Duration: —")])
            ]
          ),
          _vm._v(" " + _vm._s(_vm.suffixText.value) + " ")
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }