














































import { JobProgressDto } from '@/module/api/common';
import {
  DOMO_EXPIRATION_TTL_DAYS,
  getJobDateToCheckExpiry,
  getJobDuration,
  getJobExpiresInDuration,
  isDateExpired,
} from '@/module/api/domo';
import { formatDbDate } from '@/utils';
import { computed, defineComponent } from '@vue/composition-api';
import Vue from 'vue';

const emptyProgress: JobProgressDto = {
  totalUnits: 0,
  totalUnitsCompleted: 0,
  unitLabel: 'Loading Groups',
};

const getFriendlyStateName = (stateName: string): string => {
  if (stateName === 'IN_PROGRESS') {
    return 'In Progress';
  }
  if (stateName === 'DONE') {
    return 'Done';
  }
  if (stateName === 'CANCELLED') {
    return 'Cancelled';
  }
  if (stateName === 'ERRORED') {
    return 'Errored';
  }
  return stateName;
};

const getProgressPercent = (progress: JobProgressDto | undefined): number => {
  if (progress?.totalUnitsCompleted === undefined || progress?.totalUnits === undefined) {
    return 0;
  }
  const percentComplete = Math.round((progress.totalUnitsCompleted / progress.totalUnits) * 100);
  if (isNaN(percentComplete)) {
    return 0;
  }
  return percentComplete;
};

const getProgressSliceNumber = (progress: JobProgressDto | undefined): number => {
  const percent = getProgressPercent(progress);
  if (percent < 14) {
    return 1;
  } else if (percent < 28) {
    return 2;
  } else if (percent < 42) {
    return 3;
  } else if (percent < 56) {
    return 4;
  } else if (percent < 70) {
    return 5;
  } else if (percent < 84) {
    return 6;
  }
  return 7;
};

/**
 * TODO: Refactor to avoid the ".value" in the template. I tried at least 5
 * different variations of state management, including the typical one, and
 * nothiwas working as expected. I don't know if its some weird hangup with
 * Typescript on my local or what. Its unexplainable why the usual pattern of
 * state management isn't working here. What is here is working.
 */
export default defineComponent({
  name: 'JobStatusIcon',
  components: {},
  props: ['suffix', 'job'],
  data() {
    return {
      stateName: computed(() => this.$props.job?.state ?? ''),
      suffixText: computed(() => this.$props?.suffix ?? ''),
      progress: computed(() => this.$props?.job.progress ?? emptyProgress),
      startedAt: computed(() => this.$props?.job.startedAt ?? ''),
      completedAt: computed(() => this.$props?.job.completedAt ?? ''),
      duration: computed(() => getJobDuration(this.$props?.job ?? '')),
      isExpired: computed(() => isDateExpired(getJobDateToCheckExpiry(this.$props?.job))),
      expiresIn: computed(() => getJobExpiresInDuration(this.$props?.job ?? '')),
    };
  },
  setup(props) {
    Vue.$log.debug('JobStatusIcon: Setting job and suffix to', props);
    return {
      getProgressPercent,
      getProgressSliceNumber,
      formatDbDate,
      getFriendlyStateName,
      DOMO_EXPIRATION_TTL_DAYS,
    };
  },
});
