import { render, staticRenderFns } from "./SystemLayout.vue?vue&type=template&id=7fa672b4&"
import script from "./SystemLayout.vue?vue&type=script&lang=js&"
export * from "./SystemLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VApp,VContainer,VMain,VOverlay,VProgressLinear})


/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7fa672b4')) {
      api.createRecord('7fa672b4', component.options)
    } else {
      api.reload('7fa672b4', component.options)
    }
    module.hot.accept("./SystemLayout.vue?vue&type=template&id=7fa672b4&", function () {
      api.rerender('7fa672b4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layouts/app/SystemLayout.vue"
export default component.exports