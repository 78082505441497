import { render, staticRenderFns } from "./_base-title.vue?vue&type=template&id=a56c4f88&"
import script from "./_base-title.vue?vue&type=script&lang=js&"
export * from "./_base-title.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a56c4f88')) {
      api.createRecord('a56c4f88', component.options)
    } else {
      api.reload('a56c4f88', component.options)
    }
    module.hot.accept("./_base-title.vue?vue&type=template&id=a56c4f88&", function () {
      api.rerender('a56c4f88', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/_base/_base-title.vue"
export default component.exports