











import { defineComponent } from '@vue/composition-api';
import AuthBridgePage from '@/views/auth-bridge/AuthBridgePage.vue';
import { AuthBridgeActionName } from '@/module/public-auth-bridge';

export default defineComponent({
  name: 'AuthBridgeLayout',
  components: { AuthBridgePage },
  props: ['publicAuthBridgeUuid', 'domoSetLoadJobUuid', 'action', 'region'],

  setup(props) {
    return {
      AuthBridgeActionName
    };
  }
});
