<template>
  <v-app-bar clipped-left app dark>
    <v-app-bar-nav-icon @click="drawerToggle()" />
    <v-avatar tile class="ml-4 mr-2 mt-n2">
      <img :src="logo" alt="Five9" />
    </v-avatar>
    <v-toolbar-title class="ml-0 pl-4">
      <top-bar-title />
    </v-toolbar-title>
    <v-spacer />
  </v-app-bar>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';

// Composables for UI (drawer, feedback, wait) and General (sysconfig, name, etc.)
import { useUi } from '@/module/ui';
import { useGeneral } from '@/module/general';
import TopBarTitle from '@/components/navigation/TopBarTitle.vue';

export default defineComponent({
  name: 'AppTopBar',
  components: { TopBarTitle },
  props: {
    source: String
  },
  setup(_, context) {
    const lgAndUp = computed(() => context.root.$vuetify.breakpoint.lgAndUp);
    const { drawerToggle } = useUi();
    const { appName, appLogoFile } = useGeneral();
    return {
      lgAndUp,
      appName: appName,
      logo: appLogoFile,
      drawerToggle
    };
  }
});
</script>
<style lang="scss" scoped>
.v-application a {
  color: #fff;
  text-decoration: none;
}
</style>
